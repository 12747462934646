import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { LoaderComponent } from '@components/loaders/loader/loader.component';
import { CreateTenantSidebarComponent } from '@tenant/components/create-tenant-sidebar/create-tenant-sidebar.component';
import { ReceivedTenantInvitesSidebarComponent } from '@tenant/components/received-tenant-invites-sidebar/received-tenant-invites-sidebar.component';
import { SelectTenantComponent } from '@tenant/components/select-tenant/select-tenant.component';
import { RestApiClient } from '@models/rest.api-client';
import { SecureLocalStorage } from '@models/secure-local-storage';
import { ServerError } from '@models/server-error';
import { Tenant } from '@models/tenant';
import {
  ActiveTenant,
  Invite,
  License,
  SelectedTenant,
  Tenant as TenantInterface,
  Tenants,
} from '@interfaces/tenant';
import { Message } from '@interfaces/alert';
import { User } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'tenant',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    LoaderComponent,
    CreateTenantSidebarComponent,
    ReceivedTenantInvitesSidebarComponent,
    SelectTenantComponent,
  ],
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
  public readonly componentName: string = 'TenantComponent';
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public userName: string = '';
  public tenants: Array<SelectedTenant> = [];
  public invites: Array<Invite> = [];
  public license: License = { tenantsAllowed: 0, tenantsCreated: 0 };
  public activeInvites: number | null = null;
  public inviteSidebarVisible: boolean = false;
  public tenantSidebarVisible: boolean = false;
  private restApiClient: RestApiClient;
  private secureLocalStorage: SecureLocalStorage;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.restApiClient = new RestApiClient();
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.getUserFromStorage();
    this.tenant();
  }

  /**
   * Retrieves the user information from the secure local storage and sets the
   * userName property.
   * This method is called during the component initialization to populate the
   * user's name.
   */
  private getUserFromStorage(): void {
    if (!this.secureLocalStorage.has('user')) {
      return;
    }

    const user: User = this.secureLocalStorage.get('user').toJSON().value;
    this.userName = user.firstName + ' ' + user.lastName;
  }

  /**
   * Retrieves the tenant and invite information from the server and stores it
   * in the component properties.
   * This method is called during the component initialization to populate the
   * tenant and invite data.
   * If an error occurs during the API call, it is handled by the `errorHandlerService`.
   * Once the data is retrieved, the `isReady$` subject is set to `true` to
   * indicate that the component is ready.
   */
  private async tenant(): Promise<void> {
    try {
      const tenant = await this.restApiClient
        .method('GET')
        .route('/tenant')
        .withAuthToken()
        .response<Tenants>();

      this.tenants = [...tenant.tenants];
      this.invites = [...tenant.invites];
      this.license = { ...tenant.license };

      this.activeInvites =
        this.invites.filter((invite) => invite.active).length || null;
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.isReady$.next(true);
  }

  /**
   * Logs the user out of the application and navigates to the 'odjava' route.
   */
  public logout(): void {
    this.router.navigateByUrl('odjava');
  }

  /**
   * Toggles the visibility of the specified sidebar.
   *
   * @param sidebar - The name of the sidebar to toggle ('invite' or 'tenant').
   */
  public toggleSidebar(sidebar: string): void {
    if (sidebar === 'invite') {
      this.inviteSidebarVisible = !this.inviteSidebarVisible;
    }

    if (sidebar === 'tenant') {
      this.tenantSidebarVisible = !this.tenantSidebarVisible;
    }
  }

  /**
   * Selects the specified tenant and stores the active tenant and dashboard
   * configuration in the secure local storage.
   * After the tenant is selected, the user is navigated to the root route.
   *
   * @param tenant - The tenant to be selected.
   * @returns A Promise that resolves when the tenant selection is complete.
   */
  public async selectTenant(tenant: SelectedTenant): Promise<void> {
    try {
      const activeTenant = await this.restApiClient
        .method('POST')
        .route('/tenant/select')
        .body({ id: tenant.id })
        .withAuthToken()
        .response<ActiveTenant>();

      this.secureLocalStorage
        .setValue(activeTenant)
        .toString()
        .encrypt()
        .store('activeTenant');

      this.secureLocalStorage
        .setValue(activeTenant.memberPreferences.dashboardLayout)
        .store('dashboardConfiguration');

      this.router.navigate(['/']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  /**
   * Sends an invitation to the specified email addresses for the given tenants.
   *
   * @param invite - An object containing the tenant IDs and email addresses to
   * send the invitation to.
   * @param invite.tenantIds - An array of tenant IDs to send the invitation to.
   * @param invite.emails - An array of email addresses to send the invitation to.
   * @returns A Promise that resolves when the invitation has been successfully sent.
   */
  public async sendInvite(invite: {
    tenantIds: Array<number>;
    emails: Array<string>;
  }): Promise<void> {
    try {
      await this.restApiClient
        .method('POST')
        .route('/invitation/send')
        .body({ tenant: invite.tenantIds, email: invite.emails })
        .withAuthToken()
        .response<Message>();

      this.messageService
        .translate(this.componentName)
        .success('inviteSuccessfullySent')
        .showMessage();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  /**
   * Stores a new tenant in the system.
   *
   * @param tenant - The tenant object to be stored.
   * @returns A Promise that resolves when the tenant is successfully stored.
   */
  public async storeTenant(tenant: Tenant): Promise<void> {
    try {
      const newTenant = await this.restApiClient
        .method('POST')
        .route('/tenant/store')
        .body(tenant.build())
        .withAuthToken()
        .response<SelectedTenant>();

      this.tenants = [...this.tenants, newTenant];

      this.toggleSidebar('tenant');
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  /**
   * Handles the acceptance or rejection of a tenant invitation.
   *
   * @param invite - An object containing the action and token for the invitation.
   * @param invite.action - The action to perform on the invitation
   * ('accept' or 'reject').
   * @param invite.token - The token associated with the invitation.
   * @returns A Promise that resolves when the invitation has been successfully
   * handled.
   */
  public async handleInvite(invite: {
    action: string;
    token: string;
  }): Promise<void> {
    try {
      if (invite.action === 'accept') {
        const newTenant = await this.restApiClient
          .method('GET')
          .route(`/invitation/${invite.action}/${invite.token}`)
          .withAuthToken()
          .response<SelectedTenant>();

        this.tenants = [...this.tenants, newTenant];
      }

      if (invite.action === 'reject') {
        await this.restApiClient
          .method('GET')
          .route(`/invitation/${invite.action}/${invite.token}`)
          .withAuthToken()
          .response<Message>();
      }

      const index = this.invites.findIndex(
        (fetchedInvite) => fetchedInvite.token === invite.token
      );
      if (index >= 0) {
        this.invites.splice(index, 1);
      }

      this.activeInvites =
        this.invites.filter((invite) => invite.active).length || null;

      this.toggleSidebar('invite');
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
