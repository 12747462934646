import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';

import { TranslationPipe } from '@pipes/translation.pipe';
import { Invite } from '@interfaces/tenant';

@Component({
  selector: 'received-tenant-invites-sidebar',
  standalone: true,
  imports: [NgIf, TranslationPipe, ButtonModule, SidebarModule, TableModule],
  templateUrl: './received-tenant-invites-sidebar.component.html',
  styleUrls: ['./received-tenant-invites-sidebar.component.scss'],
})
export class ReceivedTenantInvitesSidebarComponent {
  public readonly componentName: string =
    'ReceivedTenantInvitesSidebarComponent';
  @Input() public visible: boolean = false;
  @Input() public invites: Array<Invite> = [];
  @Output() public onHideSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onHandleInvite: EventEmitter<{
    action: string;
    token: string;
  }> = new EventEmitter<{ action: string; token: string }>();
  public waitingForResponse: boolean = false;

  /**
   * Emits an event to hide the sidebar component.
   */
  public hideSidebar(): void {
    this.onHideSidebar.emit();
  }

  /**
   * Handles the action for a received tenant invite.
   *
   * @param action - The action to perform on the invite, such as
   * 'accept' or 'reject'.
   * @param token - The token associated with the invite.
   */
  public handleInvite(action: string, token: string): void {
    this.waitingForResponse = true;

    this.onHandleInvite.emit({ action, token });

    this.waitingForResponse = false;
  }
}
