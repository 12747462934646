import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { z, ZodError, ZodIssue } from 'zod';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';

import { AddressSearchComponent } from '@components/address-search/address-search.component';
import { TranslationPipe } from '@pipes/translation.pipe';
import { Location } from '@models/location';
import { Tenant } from '@models/tenant';
import { License } from '@interfaces/tenant';
import { MessageService } from '@services/message.service';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'create-tenant-sidebar',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    TranslationPipe,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    SidebarModule,
    AddressSearchComponent,
  ],
  templateUrl: './create-tenant-sidebar.component.html',
  styleUrls: ['./create-tenant-sidebar.component.scss'],
})
export class CreateTenantSidebarComponent {
  public readonly componentName: string = 'CreateTenantSidebarComponent';
  @Input() public visible: boolean = false;
  @Input() public license: License = { tenantsCreated: 0, tenantsAllowed: 0 };
  @Output() public onHideSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onCreateTenant: EventEmitter<Tenant> =
    new EventEmitter<Tenant>();
  public countries: Array<{ name: string; code: string }> = [];
  public tenant!: Tenant;
  public oibVisible: boolean = false;
  public vatVisible: boolean = false;
  public jbkjsVisible: boolean = false;
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: {
    tenantName: Array<string>;
    companyName: Array<string>;
    address: Array<string>;
    country: Array<string>;
    oib: Array<string>;
    vat: Array<string>;
    jbkjs: Array<string>;
  } = {
    tenantName: [],
    companyName: [],
    address: [],
    country: [],
    oib: [],
    vat: [],
    jbkjs: [],
  };

  constructor(
    private messageService: MessageService,
    private translationService: TranslationService
  ) {
    this.countries = [
      { name: 'Croatia', code: 'HRV' },
      { name: 'Serbia', code: 'SRB' },
      { name: 'Bosnia and Herzegovina', code: 'BIH' },
    ];
    this.tenant = new Tenant();
  }

  /**
   * Toggles the visibility of the OIB, VAT, and JBKJS fields based on the
   * selected country.
   *
   * @param country - The selected country code.
   */
  private toggleIdFields(country: string): void {
    if (country === 'HRV') {
      this.oibVisible = true;
      this.vatVisible = false;
      this.jbkjsVisible = false;
      return;
    }

    if (country === 'SRB') {
      this.oibVisible = false;
      this.vatVisible = true;
      this.jbkjsVisible = true;
      return;
    }

    this.oibVisible = false;
    this.vatVisible = true;
    this.jbkjsVisible = false;
  }

  /**
   * Formats a validation error message using the provided translation service
   * and issue details.
   *
   * @param issue - The validation issue object containing details about the error.
   * @returns The formatted validation error message.
   */
  private formatValidationError(issue: any): string {
    const key = this.translationService.getInstant(
      issue.path,
      this.componentName
    );

    let param = { field: key, length: 0 };

    if (issue.minimum) {
      param = { ...param, length: issue.minimum };
    }

    if (issue.maximum) {
      param = { ...param, length: issue.maximum };
    }

    return this.translationService.getInstant(
      issue.message,
      this.componentName,
      param
    );
  }

  /**
   * Validates the tenant form data and returns a boolean indicating if the
   * validation passed.
   *
   * The validation checks the following fields:
   * - tenantName: Must be at least 3 characters long
   * - companyName: Must be between 3 and 64 characters long
   * - address: Must not be empty
   * - country: Must not be empty
   * - oib: If visible, must be exactly 11 characters long
   * - vat: If visible, must be between 1 and 15 characters long
   * - jbkjs: If visible, must be between 1 and 15 characters long
   *
   * If any of the fields fail validation, the method will return `false` and
   * populate the `validationError` object with the specific error messages.
   *
   * @returns `true` if the validation passes, `false` otherwise.
   */
  private validation(): boolean {
    let FormData = z
      .object({
        tenantName: z.string().min(3, 'minLengthField'),
        companyName: z
          .string()
          .min(3, 'minLengthField')
          .max(64, 'maxLengthField'),
        address: z.string().min(1, 'requiredField'),
        country: z.string().min(1, 'requiredField'),
      })
      .strict();

    if (this.oibVisible) {
      FormData = FormData.extend({
        oib: z.string().length(11, 'exactLengthField'),
      });
    }

    if (this.vatVisible) {
      FormData = FormData.extend({
        vat: z.string().min(1, 'requiredField').max(15, 'maxLengthField'),
      });
    }

    if (this.jbkjsVisible) {
      FormData = FormData.extend({
        jbkjs: z.string().min(1, 'requiredField').max(15, 'maxLengthField'),
      });
    }

    try {
      FormData.passthrough().parse(this.tenant);

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors = error.flatten((issue: ZodIssue) =>
          this.formatValidationError(issue)
        ).fieldErrors;

        this.validationError = {
          tenantName: fieldErrors['tenantName'] ?? [],
          companyName: fieldErrors['companyName'] ?? [],
          address: fieldErrors['address'] ?? [],
          country: fieldErrors['country'] ?? [],
          oib: fieldErrors['oib'] ?? [],
          vat: fieldErrors['vat'] ?? [],
          jbkjs: fieldErrors['jbkjs'] ?? [],
        };
      }
    }

    return false;
  }

  /**
   * Emits an event to hide the sidebar component.
   */
  public hideSidebar(): void {
    this.onHideSidebar.emit();
  }

  /**
   * Creates a new tenant.
   *
   * This method is responsible for validating the tenant data, checking if the
   * maximum number of tenants has been reached, and then emitting an event to
   * create the new tenant.
   *
   * If the validation fails or the maximum number of tenants has been reached,
   * appropriate error messages are displayed.
   */
  public createTenant(): void {
    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    if (this.license.tenantsAllowed <= this.license.tenantsCreated) {
      this.messageService
        .translate(this.componentName)
        .warning('maxTenantsCreated')
        .showMessage();
      return;
    }

    this.waitingForResponse = true;

    this.onCreateTenant.emit(this.tenant);

    this.waitingForResponse = false;
  }

  /**
   * Selects an address location and updates the tenant's country accordingly.
   * Also toggles the visibility of ID fields based on the selected country.
   *
   * @param location - The selected address location.
   */
  public selectAddress(location: Location): void {
    this.tenant.country = location.countryCode;

    this.toggleIdFields(location.countryCode);
  }

  /**
   * Toggles the visibility of ID fields based on the selected country.
   *
   * This method is called when the selected country is changed. It updates the
   * visibility of ID fields based on the provided country code.
   *
   * @param countryCode - The ISO 3166-1 alpha-2 country code.
   */
  public changeSelectedCountry(): void {
    this.toggleIdFields(this.tenant.country);
  }
}
