import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';

import { TranslationPipe } from '@pipes/translation.pipe';

@Component({
  selector: 'module-navigation-sidebar',
  standalone: true,
  imports: [TranslationPipe, ButtonModule, SidebarModule],
  templateUrl: './module-navigation-sidebar.component.html',
  styleUrls: ['./module-navigation-sidebar.component.scss'],
})
export class ModuleNavigationSidebarComponent {
  public readonly componentName: string = 'ModuleNavigationSidebarComponent';
  @Input() public userName: string = '';
  @Input() public userEmail: string = '';
  @Input() public userAvatarUrl: string = '';
  @Input() public companyName: string = '';
  @Input() public visible: boolean = false;
  @Output() public onHideSidebar: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}

  /**
   * Hides the sidebar by emitting an event.
   */
  public hideSidebar(): void {
    this.onHideSidebar.emit();
  }

  /**
   * Navigates to the specified route and hides the sidebar.
   *
   * @param route - The route to navigate to.
   */
  public navigateTo(route: string): void {
    this.hideSidebar();
    this.router.navigateByUrl(route);
  }
}
