<div class="action-container">
  <p-button
    styleClass="p-button-outlined p-button-contrast action-button"
    label="{{ 'invites' | translation : componentName }}"
    icon="pi pi-envelope"
    badge="{{ activeInvites }}"
    badgeClass="action-button-badge"
    (click)="toggleSidebar('invite')"
  ></p-button>
  <span class="user-name">{{ userName }}</span>
  <button
    pButton
    class="p-button-outlined p-button-contrast action-button"
    label="{{ 'logout' | translation : componentName }}"
    (click)="logout()"
  ></button>
</div>

<p-table
  #tenantTable
  [value]="tenants"
  styleClass="p-datatable-striped tenants-table"
  [rowHover]="true"
  [globalFilterFields]="['tenantName']"
  selectionMode="multiple"
  [(selection)]="selectedTenants"
  dataKey="id"
  [scrollable]="true"
  scrollHeight="80vh"
  responsiveLayout="stack"
  breakpoint="600px"
>
  <ng-template pTemplate="caption">
    <div class="caption">
      <button
        pButton
        class="p-button-text p-button-raised p-button-plain table-button"
        icon="pi pi-plus"
        label="{{ 'newTenant' | translation : componentName }}"
        (click)="toggleSidebar('tenant')"
      ></button>
      <p-iconField class="icon-field" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          #filterInput
          pInputText
          type="text"
          class="filter-input"
          placeholder="{{ 'searchByName' | translation : componentName }}"
          (input)="tenantTable.filterGlobal(filterInput.value, 'contains')"
        />
      </p-iconField>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th class="index-column-header">
        {{ "index" | translation : componentName }}
      </th>
      <th>{{ "name" | translation : componentName }}</th>
      <th></th>
      <th class="selection-column-header">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-tenant let-index="rowIndex">
    <tr class="tenant-row" (click)="selectTenant(tenant)" @slideFromTop>
      <td>
        <span class="column-title">{{
          "index" | translation : componentName
        }}</span>
        {{ index + 1 }}
      </td>
      <td>
        <span class="column-title">{{
          "name" | translation : componentName
        }}</span>
        {{ tenant.tenantName }}
      </td>
      <td>
        <span *ngIf="!tenant.active" class="inactive-tenant">{{
          tenant.active.toString() | translation : componentName
        }}</span>
      </td>
      <td>
        <p-tableCheckbox
          [value]="tenant"
          (click)="$event.stopPropagation()"
        ></p-tableCheckbox>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="summary">
    <div class="summary">
      <button
        pButton
        class="p-button-text p-button-raised p-button-plain table-button"
        icon="pi pi-envelope"
        label="{{ 'sendInvite' | translation : componentName }}"
        [disabled]="waitingForResponse"
        (click)="sendInvite()"
      ></button>

      <div class="email-validation">
        <input
          pInputText
          type="text"
          class="email-input"
          [ngClass]="
            formSubmitted && email.errors ? 'invalid-field' : 'valid-field'
          "
          placeholder="{{ 'emails' | translation : componentName }}"
          id="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="invite.email"
          required
          email
        />
        <small class="validation-error">
          {{
            validationError.message
              | translation
                : componentName
                : { email: validationError.invalidEmail }
          }}
        </small>
      </div>
    </div>
  </ng-template>
</p-table>
